body {
    background-color: #e0e0e0 !important;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    min-height: 100vh;
}

.Map {
    height: 100%;
    width: 100%;
}

.leaflet-tooltip ul.attributes {
    border-top: 2px solid lightgrey;
    list-style: none;
    margin-bottom: 0;
    margin-top: 5px;
    padding-left: 10px;
    padding-top: 5px;
}

/* Remove rectangle box when clicking in Chrome */
path.leaflet-interactive:focus {
    outline: none;
}

@font-face {
    font-family: "XuntaSans";
    font-style: "normal";
    font-weight: "bold";
    src:
        local("XuntaSans-Bold"),
        url("./fonts/XuntaSans-Bold.woff") format("woff"),
        url("./fonts/XuntaSans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "XuntaSans";
    font-style: "italic";
    font-weight: "normal";
    src:
        local("XuntaSans-Italic"),
        url("./fonts/XuntaSans-Italic.woff") format("woff"),
        url("./fonts/XuntaSans-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "XuntaSans";
    font-style: "normal";
    font-weight: "normal";
    src:
        local("XuntaSans-Regular"),
        url("./fonts/XuntaSans-Regular.woff") format("woff"),
        url("./fonts/XuntaSans-Regular.woff2") format("woff2");
}

/* Style for legend in map */
.legend {
    background: white;
    background: rgba(255, 255, 255, 0.8);
    color: #555;
    font-size: 14px;

    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */

    /* border-radius: 5px; */
    line-height: 24px;
    padding: 6px 8px;
}

.legend h4 {
    color: #777;
    font-size: 16px;
    margin: 2px 12px 8px;
    text-align: center;
}

.legend span {
    bottom: 3px;
    position: relative;
}

.legend i {
    float: left;
    height: 18px;
    margin: 0 8px 0 0;
    opacity: 0.7;
    width: 18px;
}

.legend i.icon {
    background-color: rgba(255, 255, 255, 1);
    background-size: 18px;
}
